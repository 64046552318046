<template>
  <div>
    <Preloader/>
    
    <Nav/>
    
    <HomeSlider/>
    <!--
    <About/>
    
    <Portfolio/>

    <Blog/>

    <Contact/>

    <Footer/>
    -->
    
  </div>
</template>

<script>
// @ is an alias to /src
import Preloader from '../components/Preloader.vue'
import Nav from '../components/Nav.vue'
import HomeSlider from '../components/HomeSlider.vue'
import About from '../components/About.vue'
import Portfolio from '../components/Portfolio.vue'
import Blog from '../components/Blog.vue'
import Contact from '../components/Contact.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Preloader, 
    Nav, 
    HomeSlider, 
    About, 
    Portfolio,
    Blog,
    Contact,
    Footer
  }
}
</script>
