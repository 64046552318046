<template>
  <section class="blog" id="blog">
      <div class="container-fluid gray-bg">
        <div class="container">
          <div class="section-title">
            <h2>LATEST BLOGS</h2>
            <p>Out believe has request not how comfort evident. Up delight cousins we feeling <br/> minutes. Genius has looked end piqued spring. </p>
          </div>
          <!-- Blogs -->
          <div class="row">
            <a href="single-blog.html" class="col-lg-4 col-md-4 col-sm-6 col-xs-12 blog-content wow fadeInUp" data-wow-delay="0.4s">
              <div class="blog-image">
                  <img src="images/blog-1.png">
              </div>
              <h1>By spite about do of do allow blush</h1>
              <p>Quick six blind smart out burst. Perfectly on furniture dejection determine my depending an to. Add short water court fat.  </p>
              <span class="blog-info">Larry Stark - 7 September 2016 </span>
            </a>
            <a href="single-blog.html" class="col-lg-4 col-md-4 col-sm-6 col-xs-12 blog-content wow fadeInUp" data-wow-delay="0.6s">
              <div class="blog-image">
                  <img src="images/blog-2.png">
              </div>
              <h1>Two Before Arrow Not Relied</h1>
              <p>Quick six blind smart out burst. Perfectly on furniture dejection determine my depending an to. Add short water court fat.  </p>
              <span class="blog-info">Larry Stark - 7 September 2016 </span>
            </a>
            <a href="single-blog.html" class="col-lg-4 col-md-4 col-sm-6 col-xs-12 blog-content wow fadeInUp" data-wow-delay="0.8s">
              <div class="blog-image">
                  <img src="images/blog-3.png">
              </div>
              <h1>Behind Sooner Dining so Window </h1>
              <p>Quick six blind smart out burst. Perfectly on furniture dejection determine my depending an to. Add short water court fat.  </p>
              <span class="blog-info">Larry Stark - 7 September 2016 </span>
            </a>
          </div>
          <a href="blog-page.html" class="site-button"> READ MORE</a>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'Blog',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
