<template>
  <div id="preloader">
    <div class="spinner">
      <div class="uil-ripple-css" style="transform:scale(0.29);"><div></div><div></div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
