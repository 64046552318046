<template>
  <section class="contact" id="contact">
    <div class="container">
      <div class="section-title">
        <h2>GET IN TOUCH</h2>
        <p>Out believe has request not how comfort evident. Up delight cousins we feeling <br/> minutes. Genius has looked end piqued spring. </p>
      </div>
      <!-- Contact Info -->
      <div class="row">
        <div class="col-md-offset-2 col-md-8 contact-information">
          <div class="col-md-4 col-sm-4 col-xs-12 contact-info wow fadeInUp" data-wow-delay="0.4s">
              <i class="fa fa-phone" aria-hidden="true"></i>
              <p>49 0216 514 25 25 <br/>0216 514 25 25</p>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 contact-info wow fadeInUp" data-wow-delay="0.6s">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              <p>1444 S. Alameda Street Los Angeles, California 90021</p>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-12 contact-info wow fadeInUp" data-wow-delay="0.8s">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <p>info@alberto.com <br/> contact@alberto.com</p>
          </div>
        </div> <!-- information end -->
        <!--Contact Form-->
        <div class="col-md-8 col-md-offset-2 wow fadeInUp" data-wow-delay="1s">
          <form class="col-md-12 contact-form" method="POST" action="mail.php">
            <div class="row">
              <!--Name-->
              <div class="col-md-6">
                <input id="con_name" name="con_name" class="form-inp requie" type="text" placeholder="Name">
              </div>
              <!--Email-->
              <div class="col-md-6">
                <input id="con_email" name="con_email" class="form-inp requie" type="text" placeholder="Email">
              </div>
              <div class="col-md-12">
                <!--Message-->
                <textarea name="con_message" id="con_message" class="requie" placeholder="Message" rows="8"></textarea>
                <input id="con_submit" class="site-button" type="submit" value="SEND A MESSAGE">
              </div>
            </div>
          </form>
        </div> <!-- contact form end -->
      </div>    
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
