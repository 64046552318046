<template>
  <section class="about" id="about">
    <div class="container"> 
        <div class="row">
            <div class="col-md-6 about-image wow fadeInUp" data-wow-delay="0.4s">
                <img src="images/about.jpg">
            </div>
            <div class="col-md-6 about-text wow fadeInUp" data-wow-delay="0.8s">
                <div class="out">
                <h2>I am Dynamic and Creative</h2>
                <br/>
                <p>
                Impossible considered invitation him men instrument saw celebrated unpleasant. Put rest and must set kind next many near nay. He exquisite continued explained middleton am. Voice hours young woody has she think equal. Estate moment he at on wonder at season little. Six garden result summer set family esteem nay estate. 
                <br/><br/>
                Her extensive perceived may any sincerity extremity. Indeed add rather may pretty see. Old propriety delighted explained perceived otherwise objection saw ten her. Doubt merit sir the right these alone keeps. By sometimes intention smallness he northward.
                    </p>
                </div>
            </div>
        </div>
    </div> <!-- Container end -->
    <div class="container-fluid gray-bg">
        <div class="container what-can">
            <!-- Graphic Design -->
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 feature">
                <i data-icon="1" class="icon"></i>
                <h3>GRAPHIC DESIGNER</h3>
                <p>Her extensive perceived may any sincerity extremity. </p>
            </div>
            <!-- Graphic Design -->
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 feature">
                <i data-icon="C" class="icon"></i>
                <h3>PHOTOGRAPHY</h3>
                <p>Her extensive perceived may any sincerity extremity. </p>
            </div>
            <!-- Graphic Design -->
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 feature">
                <i data-icon="!" class="icon"></i>
                <h3>DEVELOPMENT</h3>
                <p>Her extensive perceived may any sincerity extremity. </p>
            </div>
            <!-- Graphic Design -->
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 feature">
                <i data-icon="#" class="icon"></i>
                <h3>CUSTOMIZATION</h3>
                <p>Her extensive perceived may any sincerity extremity. </p>
            </div>
        </div>
    </div>
    </section>
</template>

<script>
export default {
  name: 'About'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
