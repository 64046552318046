<template>
  <footer>
    <div class="container">
      <div class="social">
        <a href="slider-version.html#"><i class="fa fa-facebook" aria-hidden="true"></i>  </a>
        <a href="slider-version.html#"><i class="fa fa-twitter" aria-hidden="true"></i>  </a>
        <a href="slider-version.html#"><i class="fa fa-instagram" aria-hidden="true"></i>  </a>
        <a href="slider-version.html#"><i class="fa fa-google-plus" aria-hidden="true"></i>  </a>
        <a href="slider-version.html#"><i class="fa fa-behance" aria-hidden="true"></i>  </a>
        <a href="slider-version.html#"><i class="fa fa-dribbble" aria-hidden="true"></i>  </a>
      </div>
      <p>Copyright © 2016 Stephans, All rights Reserved. <br/>
Created by Tavillathemes</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
