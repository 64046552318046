<template>
  <nav>
    <div class="row">
      <div class="container">
        <div class="logo">
            <img src="/images/logo.png">
        </div>
        <div class="responsive"><i data-icon="m" class="icon"></i></div>
        <ul class="nav-menu">
            <li><a href="#home" class="smoothScroll">Home</a></li>
            <li><a href="#about" class="smoothScroll">About</a></li>
            <li><a href="#portfolio" class="smoothScroll">Portfolio</a></li>
            <li><a href="#blog" class="smoothScroll">Blog</a></li>
            <li><a href="#contact" class="smoothScroll">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Nav'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
