<template>
  <section class="portfolio" id="portfolio">
      <div class="container">
        <div class="section-title">
            <h2>PORTFOLIO</h2>
            <div class="portfolio_filter">
                <ul>
                    <li class="select-cat" data-filter="*">All</li>
                    <li data-filter=".web-design">Web Design</li>
                    <li data-filter=".aplication">Applications</li>
                    <li data-filter=".development">Development</li>
                </ul>
            </div>
        </div>
        <!--Portfolio Items-->  
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="isotope_items row">
                    <!-- Item -->
                    <a href="https://www.youtube.com/watch?v=M-M3rdL_WLQ" class="popup-youtube single_item link development col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                        <img src="images/masonry/work-1.jpg" alt=""> 
                        <i class="fa fa-play" aria-hidden="true"></i>   
                    </a>
                    <!-- Item -->
                    <a href="images/work-2.jpg" class="single_item link aplication col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.6s">
                        <img src="images/masonry/work-2.jpg" alt=""> 
                    </a>
                    <!-- Item -->
                    <a href="images/work-3.jpg" class="single_item link development col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.9s">
                        <img src="images/masonry/work-3.jpg" alt=""> 
                    </a>
                    <!-- Item -->
                    <a href="images/work-4.jpg" class="single_item link web-design col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="1.5s">
                        <img src="images/masonry/work-4.jpg" alt=""> 
                    </a>
                    <!-- Item -->
                    <a href="images/work-5.jpg" class="single_item link aplication col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="1.8s">
                        <img src="images/masonry/work-5.jpg" alt=""> 
                    </a>
                    <!-- Item -->
                    <a href="images/work-6.jpg" class="single_item link aplication col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="1.2s">
                        <img src="images/masonry/work-6.jpg" alt=""> 
                    </a>
                    <!-- Item -->
                    <a href="http://tavonline.net/html/stephens/demos/images/work-7.jpg" class="single_item link aplication col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="2.2s">
                        <img src="images/masonry/work-7.jpg" alt=""> 
                    </a>
                    <!-- Item -->
                    <a href="http://tavonline.net/html/stephens/demos/images/work-8.jpg" class="single_item link aplication col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="2.4s">
                        <img src="images/masonry/work-8.jpg" alt=""> 
                    </a>
                    <!-- Item -->
                    <a href="http://tavonline.net/html/stephens/demos/images/work-9.jpg" class="single_item link aplication col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="2s">
                        <img src="images/masonry/work-9.jpg" alt=""> 
                    </a>
                </div>
                <div class="col-md-12 button"><a href="slider-version.html#" class="site-button"> LOAD MORE</a></div>
                
            </div>
          </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'Portfolio',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
