<template>
  <!--HOME-->
  <section class="home slider" id="home"> 
    <div class="home-content">
      <h1>I'm <span class="element">Ufuk</span></h1>
      <div class="social">
          <a href="https://github.com/ufukozcantr" target="_blank"><i class="fa fa-github" aria-hidden="true"></i>  </a>
          <a href="https://www.linkedin.com/in/ufukozcantr" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i>  </a>
          <a href="https://www.facebook.com/ufukozcantr" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i>  </a>
          <a href="https://twitter.com/ufukozcantr" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i>  </a>
          <a href="https://www.instagram.com/ufukozcantr" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i>  </a>
      </div>
      <!--<a class="home-down bounce" href="#about"><i class="fa fa-angle-down"></i></a>-->
    </div>
    <div class="home-slider">
      <div id="slides">
        <div class="slides-container">
          <img src="images/computers/computer-dark.jpeg" alt="Computer Dark">
          <img src="images/computers/computer-home.jpeg" alt="Computer Home">
          <img src="images/computers/computer-phone.jpeg" alt="Computer Phone">
          <img src="images/computers/computer-public.jpeg" alt="Computer Public">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeSlider'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
